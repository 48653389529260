import {
	ContactShadows,
	Float,
	Environment,
	Html,
	PresentationControls,
	Text,
	useGLTF,
} from '@react-three/drei'
import config from './config.js'

export default function Experience() {
	// Load a glTF model
	const computer = useGLTF('/macbook_model_fix.glb')

	return (
		<>
			<color attach="background" args={[config.color.background]} />

			<Environment preset="city" />

			<PresentationControls
				global
				polar={[-0.4, 0.2]}
				azimuth={[-1, 0.75]}
				config={{ mass: 2, tension: 400 }}
				snap={{ mass: 3, tension: 400 }}
			>
				<Float rotationIntensity={0.4}>
					<rectAreaLight
						width={2.5}
						height={1.65}
						color={config.color.light}
						intensity={65}
						position={[0, 0.55, -1.15]}
						rotation={[0.1, Math.PI, 0]}
					/>
					<primitive object={computer.scene} position-y={-1.2}>
						<Html
							transform
							wrapperClass="htmlScreen"
							distanceFactor={1.17} // 0.83
							position={[0, 1.56, -1.4]}
							rotation-x={-0.256}
						>
							<iframe src="https://teve.fi"></iframe>
						</Html>
					</primitive>
					<Text
						font="./bangers-v20-latin-regular.woff"
						position={[2, 0.6, 0.0]}
						rotation-y={-Math.PI * 0.5}
						children={'TAPIO\nLÄHDE'}
					></Text>
				</Float>
			</PresentationControls>

			<ContactShadows
				position-y={-1.2}
				opacity={0.5}
				width={20}
				height={20}
				blur={1}
				far={1.2}
			/>
		</>
	)
}
