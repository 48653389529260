export default {
	// Global config variables
	color: {
		background: '#1e1106',
		light: '#10cbd8',
		sparkle: '#ffc061',
		portalStart: '#fff',
		portalEnd: '#000',
	},
}
